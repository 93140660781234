function Code({ text }) {

    return (<>
        <p className="code">
            <code>&gt;&nbsp;</code>
            <code>{text}</code>
        </p>
    </>);

}

export default Code;