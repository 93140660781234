
import './App.css';
import Code from './components/Code';
import { useEffect, useState } from 'react';

function App() {

  const vals = [
    "i'm not a robot", //: 1 + 1 = 10
    "today is a good day",
    "moon in aries",
    "airbender/ravenclaw",
    "support/tank/mage",
    //"i don't c-sharp"
  ];
  const [randomTxt, setTxt] = useState(vals[0]);
  let idx = -1;

  useEffect(() => {
    const i = setInterval(() => {
      idx++;
      if (idx > 4) idx = 0;
      var newTxt = vals[idx];
      setTxt(newTxt);
    }, 3000);

    return () => clearInterval(i);
  }, []);


  return (<>
    <div className="App">
      <header className="App-header">
        <h3>
          <code>/web_dev<span className="me">/mark_figueroa</span></code>
        </h3>
        {/* <div className="hdr-dots">
          <span className="hdr-dot-red"></span>
        </div> */}
      </header>
      <div>
        <Code text='javascript typescript html5 css scss'></Code>
        <Code text={<span>angular angularjs backbone <span className="learning" title="learning :)">...react</span></span>}></Code>
        <Code text='c# visual basic ms-sql'></Code>

        <hr />
        <Code text='frontend development, designing or implementing designs for web ui'></Code>
        <Code text='backend api maintenance, database queries for reports'></Code>
        <Code text='enjoys learning new stuff'></Code>
        <hr />
        <Code text='english tagalog meow purr'></Code>
        <Code text={<span>i <span className="redtxt heart">&lt;3</span> coffee, cats &amp; codes <span className="ccc" title='i like shades of gray'>#ccc</span></span>}></Code>

        <hr />
        <Code text={<span>me -&gt; <a href="https://www.linkedin.com/in/m-figueroa" target="_blank">
          linkedin.com/in/m-figueroa
          </a>
        </span>}></Code>
        <Code text='version 2021.02'></Code>
        <hr />

        <Code text={<span>{randomTxt}<span className="cursor">&nbsp;_</span></span>}></Code>
      </div>
    </div>
  </>
  );
}

export default App;
